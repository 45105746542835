.noHeader .k-grid-header {
  display: none;
}

.k-filtercell .k-filtercell-operator {
  margin-right: -40px;
}

.noScrollbar .k-grid-content {
  overflow-y: visible;
}

.noScrollbar .k-grid-header {
  padding: 0px 0px 0px 0px !important;
}

.noBorders {
  border-width: 0px;
  border-style: none;
}

.noEmpty .k-grid-norecords {
  display: none;
}

.enableHorizontalScrollbar {
  display: inline-block !important;
}
/* .noHeader .k-grid td.k-state-selected, .k-grid tr.k-state-selected
{
    background-color: #0088CE;
} */

.k-grouping-header {
  display: none;
}

.k-pager-md .k-pager-info,
.k-pager-sm .k-pager-info,
.k-pager-sm .k-pager-sizes,
.k-pager-sm .k-pager-numbers {
  display: inline-block !important;
}

.k-animation-container {
  z-index: 10003;
}
