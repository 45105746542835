.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
@font-face {
  font-family: "ProximaNovaBold";
  src: local("ProximaNovaBold"), url("./fonts/Proxima_Nova_Bold.ttf") format("truetype");
  font-weight: bold;
}
@font-face {
  font-family: "MuseoSlab3";
  src: local("MuseoSlab3"), url("./fonts/Museo_Slab_3.ttf") format("truetype");
  font-weight: bold;
}
@font-face {
  font-family: "MuseoSlab1";
  src: local("MuseoSlab1"), url("./fonts/Museo_Slab_1.ttf") format("truetype");
  font-weight: bold;
}
@font-face {
  font-family: "ProximaNovaLight";
  src: local("ProximaNovaLight"), url("./fonts/Proxima_Nova_Light.ttf") format("truetype");
  font-weight: bold;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.apiCredentialText {
  font-size: 16 px;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.MuiPopover-root {
  z-index: 4005 !important;
}

.noPointerEvents {
  pointer-events: none !important;
}

.cursorNotAllowed {
  cursor: not-allowed !important;
}

.MspUILoadingAppProgress {
  position: absolute !important;
  top: 40% !important;
  left: 46% !important;
}
.parentOpacity {
  background-color: rgba(53, 53, 53, 0.2) !important;
}
/* Edit Serial Dialog */

.serialsEditModal-BackupAppliance-Grid {
  padding-left: 21px !important;
}

.serialsEditModal-BackupAppliance-ManageServices {
  padding-left: 21px !important;
}

.serialsEditModal-ContentShiled-ManageServices-Grid {
  justify-content: flex-end;
}

.serialsEditModal {
  width: 450px !important;
}
.serialsEditModal-Essential-Status,
.serialsEditModal-ContentShiled-Status {
  justify-content: end !important;
}
.serialsEditModal-Essential-Status p,
.serialsEditModal-ContentShiled-Status p {
  width: 51px !important;
}
.useIndentation {
  padding-left: 7px !important;
}
.useIndentationLevel1 {
  padding-left: 70px !important;
}
.useIndentationLevel2 {
  padding-left: 110px !important;
}
.manageService {
  width: 395px !important;
}

.SerialsTable .k-pager-sizes,
.SerialsTableBaEss .k-pager-sizes {
  margin-left: auto !important;
}

/* Prodcut Status Icon*/
.ProductStatusIcon {
  position: relative;
  top: 2px;
}

.serialsEditModal .ProductStatusIcon {
  position: relative;
  top: 0px;
}

.BarracudaBackupAppliances .ProductStatusIcon {
  position: relative;
  top: 4px;
}

.showSerialDialog .ProductStatusIcon {
  position: relative;
  top: 4px;
}

.ProductStatusIcon span {
  margin-top: -3px !important;
}

.ProductsTable .ProductStatusIcon div[data-testid="availableIcon"],
.ProductsTable .ProductStatusIcon div[data-testid="activatedIcon"],
.ProductsTable .ProductStatusIcon div[data-testid="pendingIcon"] {
  position: relative;
  top: 3px;
}

.creditCardCvvInfoIcon .MuiSvgIcon-root {
  transform: scale(1.7) !important;
}

@-moz-document url-prefix() {
  .auditUserTable .k-grid-header-wrap th:first-child a {
    width: 55px !important;
  }
  .AssignedProductsTable {
    width: 660px !important;
  }
  .showSerialDialog .MuiDialogContent-root {
    width: 100% !important;
  }
  /* TODO */
  /* overflow auto is temporarily set to hidden for small rezolutions on firefox browser for inconsistent behaviour*/
  .SerialsTable .k-virtual-content,
  .SerialsTableBaEss .k-virtual-content {
    overflow-x: hidden !important;
  }
  .addEditAccountDialog .MuiDialog-paper {
    overflow-y: scroll !important;
  }
  .ActivateAssignDialog .MuiDialogContent-root {
    overflow-y: initial !important;
  }
}

/* Add/Edit Account Dialog */
.addEditAccount .IconAddAddress {
  padding-top: 25px !important;
}

/* Add/Edit User Dialog */
.addEditUser table td {
  font-size: 12px !important;
}

/*Address Card*/
.contactSameAsBillingInfo {
  position: relative;
  top: 45% !important;
}

/* ------ACCOUNT DETAILS TAB------ */
.editAccountBtnContactColumn {
  margin-left: -65px !important;
}

.editAccountBtnBillingColumn {
  /* margin-left: 463px !important; */
  margin-right: -1100% !important;
}

.textNoWrap,
.textNoWrap .MuiTypography-h6 {
  overflow: hidden !important;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
}
.MuiDialog-paperWidthSm {
  max-width: 700px !important;
}

.addEdiUserDialog .MuiDialog-paperScrollPaper {
  flex-direction: inherit !important;
}

.invoiceActionButton {
  width: 180px !important;
}

.headerUserColumnIcon {
  position: relative;
  bottom: 2px;
}

.M365DomainTable .k-grid-content tr {
  height: 49px !important;
}

.ProductLicenseTable .k-grid-content tr {
  height: 49px !important;
}

.ProductLicenseTable .k-grid-container {
  min-height: 490px !important;
}

.headerM365LinkUserIcon {
  position: relative;
  top: -2px;
  right: -4px;
  height: 24px;
}

.AccountDetailsTab .headerM365LinkUserStatus {
  position: relative;
  top: 2px;
}

.addEditUser .MuiDialogContent-root {
  padding: 13px 24px !important;
}

.k-detail-row .SerialsTable {
  max-width: 750px !important;
}
.k-detail-row .SerialsTableBaEss {
  max-width: 810px !important;
}

:is(.UsersTab, .AuditCard) .k-grid td {
  padding-left: 8px !important;
}

.ProductFamilyErrorCount .ProductStatusIcon .MuiSvgIcon-root {
  margin-top: -2px !important;
}

#unassignedRow .MuiSvgIcon-root {
  margin-top: 4px !important;
}

.border {
  height: 20px;
}

.horizontalLine {
  border-bottom: 2px solid lightgray;
  width: 100%;
}

.verticalLine {
  border-left: 2px solid lightgray;
  height: 22px;
}

.AddEditLoginAccountAccessStep .k-hierarchy-cell .k-plus {
  display: inline !important;
}

.AccountAccessCell span:first-of-type {
  height: 19px !important;
}

.AddEditLoginAccountAccessStep .k-virtual-content {
  overflow-y: hidden !important;
}

.AccountAccessSelectAllSpacer {
  width: 35px;
}

.AccountAccessCellLayout {
  line-height: 18px !important;
}

:is(.UsersTab, .AuditCard) .k-grid-header .k-header > .k-link {
  margin: -16px -47px !important;
  padding: 16px 30px !important;
}

.deactivateDialog .MuiDialogContent-root {
  padding: 0px 24px 8px 24px !important;
}

.deleteAccountSerialsTable .k-pager-sizes {
  margin-left: 35px !important;
}

.EmptyInfoPanelContent {
  height: 550px !important;
}

/**********************/
/* START MADIA QUERY */
/**********************/

@media only screen and (max-width: 1600px) {
  /* ------GENERAL------ */
  /*LAYOUT*/
  .App {
    padding: 38px 38px 0 38px !important;
  }

  .MuiTab-root {
    font-size: 11px !important;
    min-height: 37px !important;
  }

  .MspUILoadingAppProgress {
    position: absolute !important;
    top: 35% !important;
    left: 45% !important;
  }

  /* TABS */
  .MuiTabs-fixed {
    height: 40px !important;
  }

  /* TEXT */
  .MuiTypography-body1 {
    font-size: 13px !important;
  }

  .MuiTypography-body2 {
    font-size: 11px !important;
  }

  .MuiTypography-h4 {
    font-size: 25px !important;
  }

  .MuiTypography-h5 {
    font-size: 19px !important;
  }

  .MuiTypography-h6 {
    font-size: 16px !important;
  }

  .MuiTypography-subtitle1 {
    font-size: 13px !important;
  }

  .MuiTypography-subtitle2 {
    font-size: 12px !important;
    line-height: 17px !important;
  }
  .AccountDetailsTab .MuiTypography-subtitle2 {
    font-size: 14px !important;
  }

  /* TABLE */
  .k-grid {
    font-size: 11px !important;
  }

  .k-grid-header {
    font-size: 10px !important;
  }

  .k-textbox {
    font-size: 12px !important;
  }

  /* BUTTON */
  .MuiTypography-gutterBottom {
    margin-bottom: 0;
  }

  .MuiButton-root {
    min-width: 56px !important;
    height: 30px !important;
    font-size: 11px !important;
  }
  .MuiButtonGroup-grouped {
    min-width: 40px !important;
  }
  .MuiListItem-button {
    font-size: 13px !important;
  }
  .MuiListItem-gutters {
    padding-left: 14px !important;
    padding-right: 14px !important;
  }
  /* IMG & ICONS */
  #root svg {
    transform: scale(0.82) !important;
  }

  @-moz-document url-prefix() {
    #root svg {
      transform: scale(0.92) !important;
    }
  }
  /* ------USERS TAB------ */
  :is(.UsersTab, .AuditCard) .k-grid-header .k-header > .k-link {
    margin: -16px -41px !important;
    padding: 16px 30px !important;
  }

  :is(.UsersTab, .AuditCard) .k-grid td {
    padding: 10px 14px !important;
  }

  :is(.UsersTab, .AuditCard) .k-grid td:first-child {
    padding: 10px 4px !important;
  }

  /* ------SEARCHBAR------ */
  .makeStyles-textFieldLarge-1 .MuiOutlinedInput-input {
    padding: 9.7px 10px !important;
    font-size: 13px !important;
  }

  .auditSearch svg {
    transform: scale(0.65) !important;
  }

  /* ------AUDIT USER SEARCHBAR------ */
  .AuditCard .makeStyles-textFieldLarge-2 .MuiOutlinedInput-input {
    font-size: 16px !important;
  }

  .MuiCardContent-root:last-child {
    padding: 11px !important;
  }

  /* ------ACCOUNT PANEL------ */
  :is(.AccountPanel, .AuditCard).k-grid td {
    padding: 7px 17px !important;
  }

  :is(.AccountPanel, .AuditCard) .MuiCardContent-root {
    padding: 13px !important;
  }

  :is(.AccountPanel, .AuditCard) .MuiGrid-grid-xs-9 .MuiGrid-container {
    padding-bottom: 0px !important;
  }

  /* ------ACCOUNT DETAILS TAB------ */
  .AccountDetailsTab .MuiCardHeader-root {
    padding-left: 10px !important;
  }

  .editAccountBtnContactColumn {
    margin-left: -56px !important;
  }

  /* PRODUCTS TAB */
  .ProductsTab .ProductsTable .k-grid th,
  .k-grid td {
    padding: 7px 24px !important;
  }
  .ProductsTab .AccountNameCell {
    padding: 7px 20px !important;
  }

  /* Dialog */
  .MuiDialogActions-root {
    padding-top: 0px !important;
  }
  .MuiDialogTitle-root {
    padding: 6px 24px !important;
  }
  /* Add/Edit Account Dialog */
  .MuiDialog-paperWidthSm {
    max-width: 700px !important;
  }
  .addEditAccount .MuiInputLabel-outlined.MuiInputLabel-shrink {
    transform: translate(14px, -6px) scale(0.65) !important;
  }
  .addEditAccount .MuiFormControlLabel-label {
    font-size: 10px !important;
  }
  .addEditAccount .MuiSelect-selectMenu {
    font-size: 12px !important;
  }
  .addEditAccount .MuiListItem-root {
    font-size: 12px !important;
  }
  .addEditAccount .MuiOutlinedInput-root input {
    font-size: 13px !important;
  }
  .addEditAccount .MuiFormControlLabel-root {
    padding: 5px !important;
  }
  .addEditAccount .MuiTypography-h6 {
    padding: 2px 10px !important;
  }
  .addEditAccount .DialogContentDiv {
    padding: 0px !important;
  }
  .addEditAccount .IconAddAddress {
    padding-top: 23px !important;
  }

  .addEditUser .MuiFormControlLabel-label {
    font-size: 11px !important;
  }

  .addEditUser .MuiGrid-spacing-xs-3 > .MuiGrid-item {
    padding: 3px !important;
  }
  .addEditUser table td {
    padding: 0px 24px !important;
  }
  .addEditUser .AddEditLoginAccountAccessStep table td {
    padding: 0px 0px !important;
  }
  .addEditUser .k-pager-wrap {
    padding: 3px 8px !important;
  }
  /* Serial Dialog */
  .showSerialDialog .MuiDialogContent-root {
    padding: 1px 13px !important;
  }
  .showSerialDialog .k-grid th {
    padding: 7px 24px !important;
  }
  .AccountPanel .k-grid td {
    padding: 11px 24px !important;
  }
  .showSerialDialog .MuiDialogTitle-root {
    padding: 17px 24px 6px 24px !important;
  }
  /* Unassign Dialog */
  .showUnassignDialog p {
    font-size: 13px !important;
  }

  /*Export user billing list dialog*/
  .exportBillingListDialog p {
    font-size: 13px !important;
  }

  /* Delete Dialog */
  .deleteDialog h6 {
    font-size: 15px !important;
  }
  .deleteDialog h2 {
    font-size: 17px !important;
  }
  .deleteDialog .MuiDialogContent-root {
    padding: 12px 27px 12px !important;
  }
  .deleteDialog .MuiDialogTitle-root {
    padding: 18px 24px 6px 24px !important;
  }
  .deleteDialog .MuiTypography-h6 {
    line-height: 27px !important;
  }
  /* Deactivate Dialog */
  .deactivateDialog h6 {
    font-size: 15px !important;
  }
  .deactivateDialog h2 {
    font-size: 17px !important;
  }
  .deactivateDialog .MuiDialogContent-root {
    padding: 0px 27px 12px 27px !important;
  }
  .deactivateDialog .MuiDialogTitle-root {
    padding: 18px 24px 6px 24px !important;
  }
  .deactivateDialog .MuiTypography-h6 {
    line-height: 27px !important;
  }
  /* Cancel Serial Dialog */
  .cancelSerialDialog h6 {
    font-size: 15px !important;
  }
  .cancelSerialDialog h2 {
    font-size: 17px !important;
  }
  .cancelSerialDialog .MuiDialogContent-root {
    padding: 12px 27px 12px !important;
  }
  .cancelSerialDialog .MuiDialogTitle-root {
    padding: 18px 24px 6px 24px !important;
  }
  .cancelSerialDialog .MuiTypography-h6 {
    line-height: 27px !important;
  }
  /* Edit Serial Dialog */
  .serialsEditModal {
    width: 400px !important;
  }
  .serialsEditModal-Essential-Status,
  .serialsEditModal-ContentShiled-Status {
    justify-content: flex-end !important;
  }
  /* Manage Service Dialog */
  .manageService {
    width: 330px !important;
  }
  .manageService .MuiDialogActions-root .MuiButton-root {
    margin-right: 25px !important;
  }
  /* Prodcut Status Icon*/
  .ProductStatusIcon span {
    margin-top: 1px !important;
  }
  .ProductFamilyErrorCount span {
    margin-top: 0px !important;
  }
  /*Table status filter*/
  .k-animation-container-shown .k-button {
    height: 30px !important;
    font-size: 12px !important;
  }
  .AccountPanel .MuiGrid-container .centerVertically,
  .IntegrationsAccountsTab .MuiGrid-container .centerVertically {
    margin-top: 0.1em !important;
  }
  @-moz-document url-prefix() {
    .auditUserTable .k-grid-header-wrap th:first-child a {
      width: auto !important;
    }
    .showSerialDialog .MuiDialogContent-root {
      padding: 0px 13px !important;
      width: auto !important;
    }
    .MuiGrid-container .centerVertically {
      margin-top: auto !important;
    }
  }
  .invoiceActionButton {
    width: 150px !important;
  }

  /* Account Panel responsive table pagination */
  .AccountPanel .MuiFormControl-root {
    margin-right: auto !important;
  }
  /* View Exclusions responsive table pagination */
  .viewExclusions .MuiFormControl-root {
    margin-right: auto !important;
  }

  .headerUserColumnIcon {
    position: relative;
    bottom: 2px;
  }

  .M365DomainTable .k-grid-content tr {
    height: 43px !important;
  }

  .ProductLicenseTable .k-grid-content tr {
    height: 43px !important;
  }

  .ProductLicenseTable .k-grid-container {
    min-height: 430px !important;
  }

  .headerM365LinkUserIcon {
    position: relative;
    top: -4px;
    right: -4px;
    height: 16px;
  }

  .AccountDetailsTab .headerM365LinkUserStatus {
    position: relative;
    top: 4px;
  }

  .addEditUser .MuiDialogContent-root {
    padding: 18px 34px !important;
  }
  .IntegrationsDetailsTab .MuiTypography-subtitle2 {
    font-size: 14px !important;
  }
  .ProductFamilyErrorCount .ProductStatusIcon span {
    margin-top: -1px !important;
  }
  .ProductFamilyErrorCount .ProductStatusIcon .MuiSvgIcon-root {
    margin-top: -1px !important;
  }
  #unassignedRow .MuiSvgIcon-root {
    margin-top: 5px !important;
  }
  .AccountPanel .AccountIcons {
    margin-top: 2px !important;
  }
  .k-detail-row .SerialsTable {
    max-width: 610px !important;
  }
  .k-detail-row .SerialsTableBaEss {
    max-width: 710px !important;
  }
  .LinkM365CheckBox {
    padding-left: 5px !important;
  }
  .AccountAccessCell span:first-of-type {
    height: 44px !important;
  }
  .AccountAccessSelectAllSpacer {
    width: 11px;
  }
  .addEditUser .AccountAccessCellLayout {
    line-height: 15px !important;
  }
  .addEditUser .AccountAccessUpdateIcons {
    transform: scale(0.82) !important;
  }
  .EmptyInfoPanelContent {
    height: 440px !important;
  }

  #root svg.connectWiseManageLogo {
    transform: scale(1) !important;
  }

  .AuditUserCardSearchButton .MuiOutlinedInput-input {
    padding: 8px !important;
  }
}
/**********************/
/* END MADIA QUERY */
/**********************/

@media only screen and (max-width: 1400px) {
  :is(.ProductsTab) .k-grid-header .k-header > .k-link {
    padding: 16px 9px 16px 21px !important;
  }
  :is(.ProductsTab) .k-grid td {
    padding: 10px 15px !important;
  }
}

.headerEditActionColumn a {
  width: 59px !important;
}

/* Account Panel responsive pagination */
@media only screen and (max-width: 1730px) {
  .AccountPanel .k-pager-wrap {
    font-size: 11px !important;
  }
  .AccountPanel .k-pager-info {
    margin-left: 0 !important;
  }
}
.k-pager-wrap .k-link {
  min-width: 26px !important;
  height: 24px !important;
}
.AccountPanel .k-pager-sizes {
  margin-left: auto !important;
}
/* Product Tab Table */
#unassignedRow {
  padding: 0px !important;
  padding-top: 6px !important;
}
.ProductsTab #productsEditAction {
  padding: 7px 21px !important;
}
/* Account Panel */

/* Table */
.k-pager-numbers-wrap {
  display: none !important;
}
th.k-header.active > div > div {
  color: #fff;
  background-color: #0088ce;
}
/*Table status filter*/
.k-columnmenu-item-wrapper {
  width: 230px !important;
}

.k-filter-menu-container .k-button.k-primary,
.filterStatusDropdown .k-button.k-primary {
  color: #ffffff !important;
  background-color: #0088ce !important;
}

.k-checkbox:checked {
  border-color: #0088ce !important;
  background-color: #0088ce !important;
}
.errorMessage {
  z-index: 9000 !important;
}
.importCsvDialog {
  z-index: 8999 !important;
}
.notistack-SnackbarContainer {
  z-index: 9001 !important;
}

.grayedOut {
  color: gray;
}

.mspBackground {
  background-image: url("./assets/bg_product-login.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
.mspBackgroundContainer {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.center {
  display: block !important;
  margin-left: auto !important;
  margin-right: auto !important;
}

.centerVertically {
  display: block !important;
  margin-bottom: auto !important;
  margin-top: auto !important;
}

.groupedDropDownAvailableOptions {
  padding-left: 50px !important;
}

.InvoicesTable .k-grid-content {
  overflow-x: hidden !important;
}

.M365DomainTable .k-grid-content {
  overflow-x: hidden !important;
}

.creditCardContainer {
  padding-top: 18px !important;
}

.integrationContainer {
  padding-top: 18px !important;
}

.ManageCreditCardDialog .MuiFormLabel-root {
  line-height: 2 !important;
}

.creditCardIcon {
  width: 33px !important;
  padding-right: 6px !important;
}
.MuiSelect-select .creditCardIcon {
  margin-bottom: -6px !important;
}

.creditCardActive {
  color: "rgb(8, 134, 52)";
  display: inline;
  margin: 0;
}
.creditCardExpired {
  color: "rgb(222, 49, 49)";
  display: inline;
  margin: 0;
}
.CustomDateFilter .GridColumnMenuFilterUI .k-datepicker {
  display: none !important;
}

.AssignedProductsTable .ProductStatusIcon svg:first-child {
  margin-bottom: -4px !important;
}
.IntegrationsDetailsTab #linkCw {
  transform: scale(1.3) !important;
  -moz-transform: scale(1.1) !important;
}

.IntegrationsLogsTable td span {
  overflow: hidden !important;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
}

.dialogButtonSpacing {
  margin-left: 8px !important;
}

.k-i-more-vertical::before {
  content: "\e129" !important;
}

th.k-header.active > div > div {
  color: #0088ce !important;
  background-color: rgba(0, 0, 0, 0) !important;
}

.SetupBillingTable table {
  width: 100% !important;
}

.DarkRedColor {
  color: rgb(222, 49, 49) !important;
}

.DarkGreenColor {
  color: rgb(8, 134, 52) !important;
}

.SetupBillingTable .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
  border-color: #e34050 !important;
}

.accountTable {
  width: inherit !important;
}
.accountTable .k-grid-pager {
  width: initial !important;
}

/*This is in order to hide the items per page label and dropdown from dataTable*/
.noPageSize .k-widget.k-grid.noHeader.noScrollbar.noBorders.accountTable .k-pager-wrap.k-floatwrap.k-widget.k-grid-pager .MuiFormControl-root {
  display: none !important;
}

.noPageSize .k-widget.k-grid.noHeader.noScrollbar.noBorders.accountTable .k-pager-wrap.k-pager.k-widget.k-grid-pager.k-pager-sm .k-pager-sizes.k-label {
  display: none !important;
}

.noPageSize .k-widget.k-grid.noHeader.noScrollbar.noBorders.accountTable .k-pager-wrap.k-floatwrap.k-widget.k-grid-pager .k-pager-sizes.k-label {
  display: none !important;
}

.k-grid table .k-detail-row > .k-detail-cell {
  background-color: #fafafa;
}
.k-grid table .k-detail-row > .k-detail-cell:hover {
  background-color: rgb(222 222 222 / 7%);
}

.m365domainHeader {
  font-size: large;
}
.overflowTableColumn {
  overflow: hidden !important;
}

.ProductFamilyErrorCount .ProductStatusIcon span {
  margin-top: -2px !important;
}

.resizeRoleColumnWidth colgroup col:nth-child(5),
.resizeRoleColumnWidth colgroup col:nth-child(6) {
  width: 13%;
  white-space: nowrap;
}
.resizeRoleColumnWidth colgroup col:nth-child(2) {
  width: 25%;
  white-space: nowrap;
}

.CheckM365Status .CheckM365StatusComponent {
  display: inline-flex !important;
  margin-bottom: auto !important;
  margin-top: auto !important;
  padding-left: 15px !important;
  width: 60px !important;
}

.overflowTableColumn .checkM365StatusBtn {
  padding-top: 15px !important;
  margin-left: -15px !important;
}
.AuditUserProductColumn {
  position: relative;
}
.AuditUserProductLink {
  position: absolute;
  top: 20px;
  right: 0;
  font-size: 12px;
  color: #0088ce;
}
.AuditUserLoadMoreLink {
  margin-left: auto;
  margin-top: auto;
  margin-bottom: auto;
  color: #0088ce;
}
.AuditUserLoadMorePager {
  display: flex;
  flex-direction: row;
  border-top: 1px solid lightgray;
}
.AuditUserLoadMorePager > :first-child {
  border: none;
}

.AuditUserCardSearchButton {
  margin-left: 10px !important;
}

.AuditUserCardFilterLabel {
  text-align: left;
}

.AuditUserCardFilterLabelIcon {
  display: inline;
  position: relative;
  top: 5px;
}

.noWrap {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  max-width: 100% !important;
  display: inline-block !important;
}

.m365DialogTitle {
  font-size: 19px !important;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
  font-weight: 400 !important;
  line-height: 32px !important;
  letter-spacing: 0em !important;
}
.m365DialogDomainSubTitle {
  font-size: 12px !important;
  line-height: 17px !important;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
  font-weight: 400 !important;
  letter-spacing: 0.00714em !important;
}

.m365OnlyCheckboxHelperText {
  display: inline !important;
  margin-left: -10px !important;
  color: #9c9c9c !important;
}

.MigrateSerialDialogPaper {
  max-width: 600px !important;
  width: 100% !important;
}

.privilegeRow {
  min-height: 24px;
  display: flex;
  align-items: center;
  padding: 2px 0;
}

.privilegeIconContainer {
  width: 24px;
  text-align: center;
  height: 24px;
}

.hiddenPrivilegePlaceholder {
  visibility: hidden;
  display: inline-block;
  height: 24px;
}

.privilegeText {
  padding-left: 8px;
}

.PrivilegesSectionTitle {
  font-weight: 600 !important;
}

.privilegeItem {
  padding-bottom: 8px;
}

.disabledColor {
  color: #a7a7a7 !important;
}

.AddAddressButton {
  margin-top: 7px !important;
}

.AddEditDialogContainer {
  padding: 0 0 0 10px !important;
}

.AccountDisplayNameTextField,
.AccountNotesTextField {
  width: 97% !important;
}

.AccountAddEditTextField {
  margin-bottom: 10px !important;
}

.FavIconBarracudaText {
  line-height: 12px !important;
  font-size: 14px !important;
  margin-top: 2px !important;
  padding: 0 4px !important;
  text-align: start !important;
}

.FavIconMSPAppText {
  line-height: 24px !important;
  font-size: 30px !important;
  margin-bottom: -2px !important;
  padding: 0 4px !important;
  padding-right: 25px !important;
  font-weight: 600 !important;
  min-width: 0 !important;
}

.ServiceUnavailableMessage {
  font-weight: 600 !important;
  font-size: 21px !important;
}
.ServiceUnavailableCard {
  width: 550px !important;
}

#root .ServiceUnavailableAppIcon {
  transform: scale(2.82) !important;
}

.ServiceUnavailableContainerCentered {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 70vh;
}

.ServiceUnavailableSpacer {
  margin-top: 20px !important;
  padding-bottom: 0 !important;
}

.FaviconAppIcon path:nth-child(1) {
  fill: #66b8e2 !important;
}
.FaviconAppIcon path:nth-child(2) {
  fill: #007ab8 !important;
}

.FaviconAppIcon path:nth-child(3) {
  fill: white !important;
}

.AccountLengthInfo {
  color: #9c9c9c !important;
}

.accountStateDropdown .MuiTextField-root {
  margin-right: 16px !important;
}

.k-i-plus::before,
.k-plus::before {
  content: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='currentColor'><path d='M7 10.415l4.707 4.703 4.706-4.702L15 9l-3.293 3.29L8.414 9 7 10.415z'></path></svg>");
}
.k-i-minus::before,
.k-minus::before {
  content: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='currentColor'><path d='M7 13.703L11.707 9l4.706 4.702L15 15.117l-3.293-3.29-3.293 3.29L7 13.704z'></path></svg>");
}

.addEditUser .AccountCellGrid {
  display: flex !important;
  align-items: center !important;
  flex-wrap: nowrap !important;
}

.addEditUser .AccountCellCenterVertically {
  flex: 0 1 auto !important;
  display: flex !important;
}

.addEditUser .AddEditLoginAccountAccessStep .AccountAccessGridXs {
  flex-grow: 1 !important;
  max-width: 100% !important;
  flex-basis: 0 !important;
}

.LoadingAccountStates,
.AddAddressBtn {
  margin-top: auto !important;
  display: block;
  margin-bottom: 3% !important;
}

.addEditAccount .MuiDialogContent-root {
  overflow-y: visible !important;
}

.MuiAlert-filledInfo {
  color: #fff !important;
  font-weight: 500 !important;
  background-color: #2196f3 !important;
}

.notistack-Snackbar #notistack-snackbar {
  flex: 1 0% !important;
}

.ConnectWiseLogoSvgIcon {
  width: 28px !important;
}

.DeleteSerialCheckboxGrid {
  margin-bottom: auto !important;
}

.DeleteSerialCheckboxGrid .MuiCheckbox-root {
  padding-top: 0 !important;
}

.tabsPrivileges .MuiTab-wrapper {
  align-items: self-start;
  justify-content: flex-start;
}

.k-grid td.k-state-selected,
.k-grid tr.k-state-selected {
  background-color: #299edf;
}

.k-grid td.k-state-selected,
.k-grid tr.k-state-selected > td {
  color: white;
  background-color: rgba(0, 0, 0, 0.04);
}

.k-grid tr.k-state-selected > td:hover,
.k-grid td.k-state-selected:hover {
  color: black;
}

.k-grid tr.k-state-selected .partnerIcon,
.k-grid tr.k-state-selected .aggregatorIcon {
  color: white;
}

.partnerIcon,
.aggregatorIcon,
.k-grid > td:hover .partnerIcon,
.k-grid > td:hover .aggregatorIcon,
.k-grid tr.k-state-selected > td:hover .partnerIcon,
.k-grid tr.k-state-selected > td:hover .aggregatorIcon {
  color: #007ab8;
}

.DisabledLabel {
  color: #b2b2b2;
}

.DisableColumnFilter {
  pointer-events: none;
  opacity: 0.5;
}

.no-style-link {
  color: inherit;
  text-decoration: none;
}

.pageNotFound {
  background-image: linear-gradient(#007acc, #000);
  color: #ffffff;
  font-family: sans-serif, monospace;
  padding: 0 !important;
  min-height: 88vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.NewUserPage .HelpFooter,
.NewUserPage .HelpFooter a,
.NewUserPage .LPMlabel {
  color: white !important;
}

.connectWiseManageLogo {
  fill: #4b4b4b !important;
}

.k-state-selected .connectWiseManageLogo {
  fill: white !important;
}

.k-state-selected:hover .connectWiseManageLogo {
  fill: #4b4b4b !important;
}
.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot {
  padding-right: 0px !important;
}
.MuiAutocomplete-hasPopupIcon .MuiAutocomplete-inputRoot,
.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot {
  padding-right: 0px !important;
}
